import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import UserList from "./components/pages/DashboardContent/UserList/UserList";
import SignUp from "./components/pages/LoginSignUp/SignUp";
import Login from "./components/pages/LoginSignUp/Login";
import UserDetails from "./components/pages/UserContent/UserDetails/UserDetails";
import PrivateRoute from './components/pages/AuthService/PrivateRoute';
import Header from './components/pages/Header/Header'
import Unauthorized from "./components/pages/Utils/Unauthorized";
import {AuthProvider} from "./components/pages/AuthService/AuthProvider";
import HomeScreen from "./components/pages/HomeScreen/HomeScreen";
import CurrencyConverter from "./components/pages/CurrencyConverter/CurrencyConverter";
import LicenseLink from './components/pages/Info/Licensing';
import TermsLink from './components/pages/Info/Terms ';
import PrivacyLink from './components/pages/Info/Privacy';

function App() {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<Navigate to="/home"/>}/>
                    <Route path="/home" element={<HomeScreen/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<SignUp/>}/>
		    <Route path="/license" element={<LicenseLink/>}/>
                    <Route path="/terms" element={<TermsLink/>}/>
                    <Route path="/privacy" element={<PrivacyLink/>}/>
                    <Route
                        path="/converter"
                        element={
                            <Header>
                                <CurrencyConverter/>
                            </Header>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute adminOnly={true}>
                                <Header>
                                    <UserList/>
                                </Header>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/user/:userId"
                        element={
                            <PrivateRoute>
                                <Header>
                                    <UserDetails/>
                                </Header>
                            </PrivateRoute>
                        }
                    />
                    <Route path="/unauthorized" element={<Unauthorized/>}/>
                </Routes>
            </AuthProvider>
        </Router>
    );
}

export default App;
