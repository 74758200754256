import axios from "axios";

const API_BASE_URL = "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies";
const FALLBACK_BASE_URL = "https://latest.currency-api.pages.dev/v1/currencies";

const CurrencyExchangerService = {
    async fetchCurrencyData(fromCurrency) {
        try {
            const response = await axios.get(`${API_BASE_URL}/${fromCurrency}.json`);
            return response.data[fromCurrency];
        } catch (error) {
            console.error("Primary API failed, switching to fallback.");
            try {
                const fallbackResponse = await axios.get(`${FALLBACK_BASE_URL}/${fromCurrency}.json`);
                return fallbackResponse.data[fromCurrency];
            } catch (fallbackError) {
                console.error("Both primary and fallback APIs failed:", fallbackError);
                throw new Error("Currency data could not be fetched.");
            }
        }
    },
};

export default CurrencyExchangerService;
