import React, { useEffect, useState } from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import CardForm from '../CardForm/CardForm';

const CardComponent = ({ card, handleInputChange, handleSubmit, isAdmin, userHasCard }) => {
    const [chipPosition, setChipPosition] = useState({ top: 0, left: 0 });

    const balanceStyle = {
        backgroundColor: '#f5f5f5',
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        textAlign: 'center',
        fontWeight: 'bold',
        width: '200px',
        margin: '10px auto',
        wordWrap: 'break-word',
    };

    const formatBalance = (balance, currency) => {
        if (!balance) return `0 ${currency}`;
        const formattedBalance = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(balance);
        return `${formattedBalance} ${currency}`;
    };

    useEffect(() => {
        const chipElement = document.querySelector('.rccs__chip');
        if (chipElement) {
            const chipRect = chipElement.getBoundingClientRect();
            const parentRect = chipElement.offsetParent.getBoundingClientRect();
            setChipPosition({
                top: chipRect.top - parentRect.top + 5,
                left: chipRect.left - parentRect.left + chipRect.width + 40,
            });
        }
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <Cards
                name={card.cardholderName || ''}
                number={card.cardNumber || ''}
                expiry={card.expiration || ''}
                cvc={card.cvv || ''}
            />
            {card.cardType && (
                <div
                    style={{
                        position: 'absolute',
                        top: `${chipPosition.top}px`,
                        left: `${chipPosition.left}px`,
                        color: card.cardType === 'Gold' ? '#DAA520' : 'silver',
                        fontWeight: 'bold',
                        fontSize: '17px',
                        fontStyle: 'italic',
                    }}
                    className="chip-type"
                >
                    {card.cardType.charAt(0).toUpperCase() + card.cardType.slice(1)}
                </div>
            )}
            <div style={balanceStyle} className="balance-info">
                <p><strong>Balance:</strong> {formatBalance(card.balance, card.currency)}</p>
            </div>
            <CardForm
                userHasCard={userHasCard}
                card={card}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                isAdmin={isAdmin}
            />
        </div>
    );
};

export default CardComponent;
