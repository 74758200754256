import React from 'react';
import './PdfViewer.css'

const PrivacyLink = () => {
  return (
    <div className="pdf-viewer">
      <iframe 
        src="/privacy.pdf" 
        width="100%" 
        height="100%" 
        title="PDF Viewer" 
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default PrivacyLink;
