import React from "react";
import './HomeScreen.css';
import {useNavigate} from "react-router-dom";

import backImg from '../../assets/back.webp';
import lupImg from '../../assets/lup.webp';
import img1 from '../../assets/1.png';
import img2 from '../../assets/2.png';
import img3 from '../../assets/3.png';
import img4 from '../../assets/4.png';
import img5 from '../../assets/5.png';
import img6 from '../../assets/6.png';
import img7 from '../../assets/7.png';
import imgI1 from '../../assets/i1.png';
import imgI2 from '../../assets/i2.png';
import imgI3 from '../../assets/i3.png';
import imgI4 from '../../assets/i4.png';
import imgP1 from '../../assets/p1.png';
import imgP2 from '../../assets/p2.png';
import imgP3 from '../../assets/p3.png';
import imgP4 from '../../assets/p4.png';
import phoneImg from '../../assets/phone.webp';

const HomeScreen = () => {
    const navigate = useNavigate()

    const handleLoginClick = () => {
        navigate('/login')
    }

    const handleRegisterClick = () => {
        navigate('/register')
    }

    const handleLicenseClick = () =>{
        navigate('/license')
    }

    const handleTermsClick = () =>{
        navigate('/terms')
    }

    const handlePrivacyClick = () =>{
        navigate('/privacy')
    }
    return (
        <>
        <div className='main_container'>
            <section className='first'>
                <div className="sup">
                    <h1> True Path Bank : <br/> Enable finance for <span className='inf'>infinite</span> possibilities</h1>
                    <p>API-driven banking infrastructure embeds financial services across industries and provides the foundation for regulatory compliance upon which our partners grow.</p>
                    <ul>
                        <li className='hover' onClick={handleRegisterClick}>Open account</li>
                        <li className='hover2'onClick={handleLoginClick}>Sign In</li>
                    </ul>
                </div>
                <div className="zalup">
                    <img src={lupImg} alt="Lup" />
                </div>
            </section>
            <section className='second'>
                <div className='pb_container'>
                    <span className='obser'>
                        <div className='dickpic'>
                            <img src={img1} alt="Image 1" />
                            <img src={img2} alt="Image 2" />
                            <img src={img3} alt="Image 3" />
                            <img src={img4} alt="Image 4" />
                            <img src={img5} alt="Image 5" />
                            <img src={img6} alt="Image 6" />
                            <img src={img7} alt="Image 7" />
                        </div>
                    </span>
                    <span className='obser'>
                        <div className='ver'>
                            <h1>Together, we're <span className='inf'>redefining</span> financial services</h1>
                            <p>Built on putting our partners first to provide financial technology for everyone,everywhere</p>
                        </div>
                    </span>
                </div>
            </section>
            <section className='third'>
                <div className='ahu_container'>
                    <div className='inst_pay'>
                        <div className='head'><h3 className=''>Instant Payments</h3></div>
                        <div className='fed'>
                            <h1>FedNow®: the<span className='inf'> newest</span> <br /> payment rail</h1>
                            <p>Deliver instant payments for businesses and consumers across the world. Add FedNow to your list of faster payment solutions.</p>
                        </div>
                        <ul className="any">
                            <li className='hover' onClick={handleLoginClick}>Learn More</li>
                        </ul>
                        <div className='mini_grid'>
                            <div className='gi'><img src={imgI1} alt="Icon 1" /><p>24/7/365 Fund Settlement</p></div>
                            <div className='gi'><img src={imgI2} alt="Icon 2" /><p>ISO20022 Interoperability with RTP</p></div>
                            <div className='gi'><img src={imgI3} alt="Icon 3" /><p>Direct Line to the Fed</p></div>
                            <div className='gi'><img src={imgI4} alt="Icon 4" /><p>No 3rd Party Dependencies</p></div>
                        </div>
                    </div>
                    <div className='media'>
                        <img className='media_img' src={backImg} alt="Background" />
                        <div className='before'>
                            <p className="jir">"As leaders in faster payments, we continue to offer solutions for instant & secure money movement. By providing FedNow we hope to extend faster payments reach to more US DDAs, support partner growth, and be part of this milestone in financial technology."</p>
                            <div className='quote'>
                                <div className='huyase'>
                                    
                                    <div className='brick'>
                                        <p className="mini">Keith Vander Leest</p>
                                        <span className="micro">HEAD OF PAYMENTS</span>
                                        <span className="micro"> | TRUE PATH </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ahu_container'>
                    <div className='phone'>
                        <img className='phone_img' src={phoneImg} alt="Phone" />
                        <div className='before'>
                            <p className="jir">"Our modern BaaS platform and consultative approach, combined with our relationships with Visa, Mastercard, and American Express for card issuing provide a strong foundation for our partners to build cutting edge card solutions for their users."</p>
                            <div className='quote'>
                                <div className='huyase'>
                                    <div className='brick'>
                                        <p className="mini">Andrew Lambert</p>
                                        <span className="micro">SENIOR PRODUCT DIRECTOR, CARDS STRATEGY </span>
                                        <span className="micro"> | TRUE PATH</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card_acc'>
                        <div className='head'><h3 className=''>Card & Account Programs</h3></div>
                        <div className='fed'>
                            <h1>Build <span className='inf'> better</span> <br /> card solutions</h1>
                            <p>Design your own card program to enable credit building and a streamlined customer experience. Card rails and APIs provide complete customization.</p>
                        </div>
                        <ul className="any">
                            <li className='hover' onClick={handleLoginClick}>Learn More</li>
                        </ul>
                        <div className='mini_grid'>
                            <div className='gi'><img src={imgP1} alt="Program 1" /><p>Credit Cards</p></div>
                            <div className='gi'><img src={imgP2} alt="Program 2" /><p>Debit Cards</p></div>
                            <div className='gi'><img src={imgP3} alt="Program 3" /><p>Accounts</p></div>
                            <div className='gi'><img src={imgP4} alt="Program 4" /><p>Issuing</p></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='last_container'>
                <div className='readyto'>
                    <h1 className='ww'>Ready to <span className='whi'> get started?</span></h1>
                    <p className='idor'>We bridge the best of both worlds. The technology support you want, combined with the banking expertise you need to create a customized banking solution no matter what your business needs may be.</p>
                    <p className='get' onClick={handleLoginClick}>Get In Touch</p>
                </div>
            </section>
            
        </div>
        <footer>
        <div className='fstmenu'>
          <h1>"Best Place to Work in Financial Technology"</h1>
          <p>TRUE PATH BANK - 2018 </p>
         
        
          {/* <ul>
            <li className='hover'>Career Opportunities</li>
          </ul> */}
        </div>
        <div className='scdmenu'>
          <h4> Phone :
          </h4>
          <ul>
            <li>+44 203 807 3454</li>
            <h4>Email :</h4>
            <li>support@truepathbank.com</li>
          </ul>
        </div>
        {/* <div className='scdmenu'>
          <h4> Services
          </h4>
          <ul>
            <li>Fintech</li>
            <li>Commercial Real Estate</li>
            <li>Construction Lending</li>
            <li>Lender Finance</li>
          </ul>
        </div> */}
        <div className='scdmenu'>
          <h4> Legal
          </h4>
          <ul>
            <li onClick={handleLicenseClick}>License</li>
            <li onClick={handleTermsClick}>Terms of use</li>
            <li onClick={handlePrivacyClick}>Privacy Policy</li>
          </ul>
        </div>
      </footer>
        </>
    )
}

export default HomeScreen;
