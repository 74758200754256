import React, {useState} from 'react';
import './LoginSignup.css';
import {useNavigate} from "react-router-dom";
import email_icon from "../../assets/email.png";
import password_icon from "../../assets/password.png";
import eye_closed_icon from "../../assets/public/eye-closed-svgrepo-com.svg";
import eye_open_icon from "../../assets/public/eye-open-svgrepo-com.svg";
import {LoginService} from "../../services/LoginService";
import {UserOutlined} from "@ant-design/icons";
import {notification} from "antd";

const SignUp = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleSignUp = async (e) => {
        e.preventDefault();
        try {
            await LoginService.register(firstName, lastName, username, password);
            notification.success({
                message: 'Sign-Up Successful',
                description: 'User created successfully! Please log in.',
            });
            setFirstName('');
            setLastName('');
            setUsername('');
            setPassword('');
            navigate('/login');
        } catch (error) {
            notification.error({
                message: 'Sign-Up Failed',
                description: error.response?.data || 'An error occurred during sign-up. Please try again.',
            });
        }
    };

    return (
        <div className="login-signUp">
            <div className='container'>
                <div className='header'>
                    <div className='text'>Sign Up</div>
                    <div className='underline'></div>
                </div>
                <div className='inputs'>
                    <div className="input">
                        <UserOutlined className='user-outlined-png' style={{ fontSize: '24px' }} />
                        <input
                            type='text'
                            placeholder='First Name'
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                </div>
                <div className='inputs'>
                    <div className="input">
                        <UserOutlined className='user-outlined-png' style={{ fontSize: '24px' }} />
                        <input
                            type='text'
                            placeholder='Last Name'
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                </div>
                <div className='inputs'>
                    <div className="input">
                        <img src={email_icon} alt='email icon'/>
                        <input
                            type='text'
                            placeholder='Username'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                </div>
                <div className='inputs'>
                    <div className="input">
                        <img src={password_icon} alt='password icon'/>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <img
                            src={showPassword ? eye_open_icon : eye_closed_icon}
                            alt="toggle password visibility"
                            className={showPassword ? "eye-icon open" : "eye-icon closed"}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                </div>
                <div className="submit-container">
                    <div className='submit' onClick={handleSignUp}>Sign Up</div>
                    <div className='submit gray' onClick={() => {
                        setFirstName('');
                        setLastName('');
                        setUsername('');
                        setPassword('');
                        navigate('/login');
                    }}>Login
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
