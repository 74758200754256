import React from 'react';
import './TransactionComponent.css';

import {useAuth} from "../../AuthService/AuthProvider";

const TransactionStatusOptions = Object.freeze({
    PENDING: 'PENDING',
    FAILED: 'FAILED',
    SUCCESSFUL: 'SUCCESSFUL',
});

const TransactionComponent = ({transactions, handleStatusChange}) => {
    const {user} = useAuth();

    return (
        <div className="transaction-container">
            <h3 className="transaction-h3">Transactions</h3>
            {transactions.length === 0 ? (
                <p>No Transactions</p>
            ) : (
                <div className="table-wrapper">
                    <table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Account Number</th>
                            <th>Type</th>
                            <th>Destination Bank</th>
                            <th>Destination Country</th>
                            <th>BIC</th>
                            <th>SWIFT</th>
                        </tr>
                        </thead>
                        <tbody>
                        {transactions.map((transaction, index) => (
                            <tr
                                key={index}
                                className={`transaction-row ${transaction.status.toLowerCase() === 'pending' ? 'pending' : transaction.status.toLowerCase()}`}
                            >
                                <td>{new Date(transaction.dateTime).toLocaleString()}</td>
                                <td>{transaction.amountToSend}</td>
                                <td>
                                    {user?.role === '[ROLE_ADMIN]' && !['FAILED', 'SUCCESSFUL'].includes(transaction.status) ? (
                                        <select
                                            value={transaction.status}
                                            onChange={e => handleStatusChange(transaction.id, e.target.value)}
                                        >
                                            {Object.values(TransactionStatusOptions).map(status => (
                                                <option key={status} value={status}>
                                                    {status}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <span>{transaction.status}</span>
                                    )}
                                </td>
                                <td>{transaction.accountNumber}</td>
                                <td>{transaction.type}</td>
                                <td>{transaction.destinationBank}</td>
                                <td>{transaction.destinationCountry}</td>
                                <td>{transaction.bic || '-'}</td>
                                <td>{transaction.swift || '-'}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default TransactionComponent;