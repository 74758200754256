import React, {useState} from 'react';
import './LoginSignup.css'

import email_icon from '../../assets/email.png'
import password_icon from '../../assets/password.png'
import eye_closed_icon from "../../assets/public/eye-closed-svgrepo-com.svg";
import eye_open_icon from "../../assets/public/eye-open-svgrepo-com.svg";
import {useNavigate} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import {useAuth} from "../AuthService/AuthProvider";
import {LoginService} from "../../services/LoginService";
import {notification} from "antd";

const Login = () => {
    const navigate = useNavigate();
    const {setUser} = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await LoginService.login(username, password);
            const token = data.token;

            localStorage.setItem('token', token);

            const decodedToken = jwtDecode(token);
            const role = decodedToken.role;
            const userId = decodedToken.userId;

            setUser({
                id: userId,
                role: role,
            });

            if (role === '[ROLE_ADMIN]') {
                navigate('/dashboard');
            } else {
                navigate(`/user/${userId}`, {state: {hasCard: true}});
            }
        } catch (error) {
            notification.error({
                message: 'Login Failed',
                description: error.response?.data || 'An error occurred during login. Please try again.',
            });
        }
    };

    return (
        <div className="login-signUp">
            <div className='container'>
                <div className='header'>
                    <div className='text'>Login</div>
                    <div className='underline'></div>
                </div>
                <div className='inputs'>
                    <div className="input">
                        <img src={email_icon} alt='email icon'/>
                        <input
                            type='text'
                            placeholder='Username'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                </div>
                <div className='inputs'>
                    <div className="input">
                        <img src={password_icon} alt='password icon'/>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <img
                            src={showPassword ? eye_open_icon : eye_closed_icon}
                            alt="toggle password visibility"
                            className={showPassword ? "eye-icon open" : "eye-icon closed"}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                </div>
                <div className="submit-container">
                    <div className='submit purple' onClick={handleSubmit}>Login</div>
                    <div className='submit gray' onClick={() => {
                        setUsername('');
                        setPassword('');
                        navigate('/register');
                    }}>Sign Up
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
