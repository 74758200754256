import transactionApiClient from "./TransactionApiClient";

const API_URL = process.env.REACT_APP_API_URL;

export const UserService = {
    async addUser(username) {
        const response = await transactionApiClient.post(`${API_URL}/users`, {
            username
        });
        return response.data;
    },

    async getAllUsers() {
        const response = await transactionApiClient.get(`${API_URL}/users`);
        return response.data;
    },

    async updateUser(id, username, password) {
        const response = await transactionApiClient.put(`${API_URL}/users/${id}`, {
            id,
            username,
            password,
        });
        return response.data;
    },

    async deleteUser(id) {
        const response = await transactionApiClient.delete(`${API_URL}/users/${id}`);
        return response.data;
    },

    async getUserById(userId) {
        const response = await transactionApiClient.get(`${API_URL}/users/${userId}`);
        return response.data;
    },
};
