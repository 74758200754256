import React, { useEffect } from "react";
import { Modal, Input, Button, Form } from "antd";

const UserFormModal = ({ user, onClose, onSubmit }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                username: user.username,
                password: "",
            });
        } else {
            form.resetFields();
        }
    }, [user, form]);

    const handleFormSubmit = (values) => {
        const payload = user
            ? { id: user.id, username: values.username, password: values.password || undefined }
            : { username: values.username, password: values.password };

        onSubmit(payload);

        onClose();
    };

    return (
        <Modal className="custom-modal"
            title={user ? "Edit User" : "Add User"}
            open
            onCancel={onClose}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFormSubmit}
                initialValues={{
                    username: "",
                    password: "",
                }}
            >
                <Form.Item
                    label="Username (Email)"
                    name="username"
                    rules={[
                        { required: true, message: "Please enter a username (email)." },
                        { type: "email", message: "Please enter a valid email address." },
                    ]}
                >
                    <Input size="large" placeholder="Enter email" />
                </Form.Item>

                {user && (
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            { min: 6, message: "Password must be at least 6 characters." },
                        ]}
                    >
                        <Input.Password size="large" placeholder="Enter new password (optional)" />
                    </Form.Item>
                )}

                <Form.Item>
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit">
                            {user ? "Update User" : "Add User"}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UserFormModal;
