import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import TransactionComponent from '../TransactionComponent/TransactionComponent';
import MoneyTransferComponent from '../MoneyTransferComponent/MoneyTransferComponent';
import CardComponent from '../CardComponent/CardComponent';
import './UserDetails.css';
import {useAuth} from "../../AuthService/AuthProvider";
import {UserCardService} from "../../../services/UserCardService";
import {UserService} from "../../../services/UserService";
import {TransactionService} from "../../../services/TransactionService";
import moment from 'moment';
import {notification} from "antd";
import CurrencyTicker from "../../CurrencyTicker/CurrencyTicker";
import Footer from '../../Footer/footer';

const UserDetails = () => {
    const { user } = useAuth();
    const { userId } = useParams();
    const { state } = useLocation();
    const [card, setCard] = useState({ cardNumber: '', currency: '', expiration: '', cvv: '', balance: 0, cardType: '' });
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        UserService.getUserById(userId).then(response => {
            if (response.card) {
                setCard(response.card);
            } else {
                setCard({ cardNumber: '', currency: '', expiration: '', cvv: '', balance: 0, cardType: '' });
            }
        }).catch(error => {
            notification.error({
                message: 'Failed to Load Details',
                description: error.response?.data || 'An error occurred while loading user details.',
            });
        });

        TransactionService.getAllTransactionsByUserId(userId)
            .then(response => setTransactions(response))
            .catch(error => {
                notification.error({
                    message: 'Failed to Load Transactions',
                    description: error.response?.data || 'An error occurred while loading transactions.',
                });
            });
    }, [userId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCard(prevCard => ({
            ...prevCard,
            [name]: value,
        }));
    };

    const handleSubmit = (values) => {
        const cardData = {
            ...card,
            cardNumber: card.cardNumber.replace(/\s/g, ''),
            cardType: values.cardType,
        };
        UserCardService.updateCard(userId, cardData)
            .then(() => {
                setCard(cardData);
                notification.success({
                    message: 'Card Updated Successfully',
                    description: 'The card details were successfully updated.',
                });
            })
            .catch(error => {
                notification.error({
                    message: 'Card Update Failed',
                    description: error.response?.data || 'An error occurred while updating the card.',
                });
            });
    };

    const handleMoneyTransferSubmit = (transferData) => {
        const dateTime = moment().format('YYYY-MM-DDTHH:mm:ss');
        const updatedTransferData = { ...transferData, dateTime };
        const apiCall = user?.role === '[ROLE_ADMIN]'
            ? TransactionService.createDeposit
            : TransactionService.createWithdraw;

        apiCall(userId, updatedTransferData)
            .then((newTransaction) => {
                setTransactions(prevTransactions => [newTransaction, ...prevTransactions]);
                notification.success({
                    message: 'Transaction Created',
                    description: 'The transaction was successfully created.',
                });
            })
            .catch(error => {
                notification.error({
                    message: 'Transaction Failed',
                    description: error.response?.data || 'An error occurred while processing the transaction.',
                });
            });
    };

    const handleStatusChange = (transactionId, newStatus) => {
        TransactionService.updateTransactionStatus(userId, transactionId, newStatus)
            .then(() => {
                setTransactions(prevTransactions =>
                    prevTransactions.map(transaction =>
                        transaction.id === transactionId
                            ? { ...transaction, status: newStatus }
                            : transaction
                    )
                );
                notification.success({
                    message: 'Transaction Status Updated',
                    description: 'The transaction status was successfully updated.',
                });

                UserService.getUserById(userId)
                    .then(response => {
                        setCard(response.card);
                    })
                    .catch(error => {
                        notification.error({
                            message: 'Failed to Update Card Balance',
                            description: error.response?.data || 'An error occurred while updating the card balance.',
                        });
                    });
            })
            .catch(error => {
                notification.error({
                    message: 'Failed to Update Status',
                    description: error.response?.data || 'An error occurred while updating the transaction status.',
                });
            });
    };

    return (
        <>
            <CurrencyTicker/>
            <div className="user-details-container">
                <header className="header"></header>

                <div className="main-content">
                    <div className="left-section">
                        <CardComponent
                            userHasCard={state?.hasCard ?? false}
                            card={card}
                            handleInputChange={handleInputChange}
                            handleSubmit={handleSubmit}
                            isAdmin={user?.role === '[ROLE_ADMIN]'}
                        />
                    </div>

                    <div className="right-section">
                        <div className="transaction-component">
                            <TransactionComponent
                                transactions={transactions}
                                handleStatusChange={handleStatusChange}
                            />
                        </div>
                        <div className="money-transfer-component">
                            <MoneyTransferComponent
                                handleMoneyTransferSubmit={handleMoneyTransferSubmit}
                                userHasCard={state?.hasCard ?? false}
                                balance={card.balance || 0}
                            />
                        </div>
                    </div>
                </div>
		<Footer/>
            </div>
        </>

    );
};


export default UserDetails;
