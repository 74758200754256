import React, { useEffect } from 'react';
import './CardForm.css';
import { Button, Form, Input, Select } from 'antd';
import { CardType } from '../../Utils/Enums/CardType';

const { Option } = Select;

const CardForm = ({ card, handleInputChange, handleSubmit, isAdmin, userHasCard }) => {
    const [form] = Form.useForm();

    const formatCardNumber = (value) => {
        return value.replace(/(\d{4})(?=\d)/g, '$1 ').trim();
    };

    useEffect(() => {
        form.setFieldsValue({
            cardNumber: card.cardNumber ? formatCardNumber(card.cardNumber) : '',
            expiration: card.expiration || '',
            cvv: card.cvv || '',
            cardholderName: card.cardholderName || '',
            cardType: card.cardType || undefined,
        });
    }, [card, form]);

    const handleCardNumberChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 16) value = value.slice(0, 16);
        const formattedValue = formatCardNumber(value);
        handleInputChange({ target: { name: 'cardNumber', value: formattedValue } });
        form.setFieldsValue({ cardNumber: formattedValue });
    };

    const handleExpirationChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 4) value = value.slice(0, 4);
        if (value.length >= 3) {
            value = `${value.slice(0, 2)}/${value.slice(2)}`;
        }
        handleInputChange({ target: { name: 'expiration', value } });
        form.setFieldsValue({ expiration: value });
    };

    const handleCvvChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 3) value = value.slice(0, 3);
        handleInputChange({ target: { name: 'cvv', value } });
        form.setFieldsValue({ cvv: value });
    };

    const handleCardholderNameChange = (e) => {
        const value = e.target.value;
        handleInputChange({ target: { name: 'cardholderName', value } });
        form.setFieldsValue({ cardholderName: value });
    };

    const handleCardTypeChange = (value) => {
        handleInputChange({ target: { name: 'cardType', value } });
        form.setFieldsValue({ cardType: value });
    };

    const handleFormSubmit = (values) => {
        handleSubmit(values);
    };

    return (
        <>
            {isAdmin && (
                <Form
                    form={form}
                    name="cardForm"
                    layout="vertical"
                    onFinish={handleFormSubmit}
                    initialValues={{
                        cardNumber: card.cardNumber ? formatCardNumber(card.cardNumber) : '',
                        expiration: card.expiration || '',
                        cvv: card.cvv || '',
                        cardholderName: card.cardholderName || '',
                        cardType: card.cardType || undefined,
                    }}
                >
                    <Form.Item
                        label="Card Number"
                        name="cardNumber"
                        rules={[
                            { required: true, message: 'Please enter a valid card number' },
                            {
                                validator: (_, value) =>
                                    value && value.replace(/\s/g, '').length === 16
                                        ? Promise.resolve()
                                        : Promise.reject(new Error('Card number must be 16 digits')),
                            },
                        ]}
                    >
                        <Input
                            placeholder="1234 5678 9123 4567"
                            onChange={handleCardNumberChange}
                            maxLength={19}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Expiration Date"
                        name="expiration"
                        rules={[
                            {
                                required: true,
                                pattern: /^(0[1-9]|1[0-2])\/\d{2}$/,
                                message: 'Please enter a valid expiration date (MM/YY)',
                            },
                        ]}
                    >
                        <Input
                            placeholder="MM/YY"
                            onChange={handleExpirationChange}
                            maxLength={5}
                        />
                    </Form.Item>

                    <Form.Item
                        label="CVV"
                        name="cvv"
                        rules={[
                            { required: true, pattern: /^\d{3}$/, message: 'CVV must be 3 digits' },
                        ]}
                    >
                        <Input
                            placeholder="123"
                            onChange={handleCvvChange}
                            maxLength={3}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Cardholder Name"
                        name="cardholderName"
                        rules={[{ required: true, message: 'Cardholder name cannot be empty' }]}
                    >
                        <Input
                            placeholder="Enter name"
                            onChange={handleCardholderNameChange}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Card Type"
                        name="cardType"
                        rules={[{ required: true, message: 'Please select a card type' }]}
                    >
                        <Select
                            placeholder="Select Card Type"
                            onChange={handleCardTypeChange}
                        >
                            <Option value={CardType.GOLD}>{CardType.GOLD}</Option>
                            <Option value={CardType.PLATINUM}>{CardType.PLATINUM}</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            disabled={!userHasCard}
                            title={!userHasCard ? 'Please create a card before modifying it' : ''}
                        >
                            Save Changes
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </>
    );
};

export default CardForm;
