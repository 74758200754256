import React, { useEffect, useState } from "react";
import "./CurrencyTicker.css";
import CurrencyExchangerService from "../../services/CurrencyExchangerService";

const CurrencyTicker = ({ baseCurrency = "usd", desiredCurrencies = ["eur", "btc", "eth"] }) => {
    const [tickerText, setTickerText] = useState("");
    const [animationDuration, setAnimationDuration] = useState("15s");

    useEffect(() => {
        const fetchConversions = async () => {
            try {
                const data = await CurrencyExchangerService.fetchCurrencyData(baseCurrency);
                const conversionsArray = Object.entries(data)
                    .filter(([key]) => desiredCurrencies.includes(key))
                    .map(([key, value]) => `${baseCurrency.toUpperCase()} → ${key.toUpperCase()}: ${value}`);

                const text = conversionsArray.join("  |  ");
                setTickerText(text);
            } catch (error) {
                console.error("Failed to fetch currency data:", error);
                setTickerText("Unable to load currency data");
            }
        };

        fetchConversions();
    }, [baseCurrency, desiredCurrencies]);

    useEffect(() => {
        if (tickerText) {
            const tickerElement = document.querySelector(".ticker-text");
            if (tickerElement) {
                const textWidth = tickerElement.offsetWidth;
                const containerWidth = window.innerWidth;

                const duration = Math.max((textWidth / containerWidth) * 15, 15);
                setAnimationDuration(`${duration}s`);
            }
        }
    }, [tickerText]);

    return (
        <div className="ticker-container">
            <div
                className="ticker-text"
                style={{ animationDuration }}
            >
                {tickerText || "Loading..."}
            </div>
        </div>
    );
};

export default CurrencyTicker;
