import React, { useState, useEffect } from "react";
import '../CurrencyConverter/CurrencyConverter.css';
import { HiSwitchHorizontal } from "react-icons/hi";
import { Select, Input } from "antd";
import CurrencyExchangerService from "../../services/CurrencyExchangerService";
const CurrencyConverter = () => {
    const [info, setInfo] = useState([]);
    const [input, setInput] = useState("");
    const [from, setFrom] = useState("usd");
    const [to, setTo] = useState("inr");
    const [options, setOptions] = useState([]);
    const [output, setOutput] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await CurrencyExchangerService.fetchCurrencyData(from);
                setInfo(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [from]);

    useEffect(() => {
        const formattedOptions = Object.keys(info).map((currency) => ({
            value: currency,
            label: currency.toUpperCase(),
        }));
        setOptions(formattedOptions);
        convert();
    }, [info]);

    useEffect(() => {
        convert();
    }, [input, from, to]);

    const convert = () => {
        const rate = info[to];
        if (rate && input) {
            const rawOutput = (parseFloat(input) * rate).toFixed(5); // 5 decimal places
            const formattedOutput = new Intl.NumberFormat('en-US', { minimumFractionDigits: 5, maximumFractionDigits: 5 }).format(rawOutput);
            setOutput(formattedOutput);
        } else {
            setOutput("");
        }
    };

    const flip = () => {
        setFrom(to);
        setTo(from);
    };

    return (
        <div className="CurrencyConverter">
            <div className="container">
                <div className="left">
                    <h3>Amount</h3>
                    <Input
                        type="number"
                        placeholder="Enter the amount"
                        onChange={(e) => setInput(e.target.value)}
                        value={input}
                    />
                </div>
                <div className="middle">
                    <h3>From</h3>
                    <Select
                        showSearch
                        placeholder="Select currency"
                        onChange={(value) => setFrom(value)}
                        value={from}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        options={options.map((opt) => ({
                            value: opt.value,
                            label: opt.label,
                        }))}
                    />
                </div>
                <div className="switch">
                    <HiSwitchHorizontal size="30px" onClick={flip} />
                </div>
                <div className="right">
                    <h3>To</h3>
                    <Select
                        showSearch
                        placeholder="Select currency"
                        onChange={(value) => setTo(value)}
                        value={to}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        options={options.map((opt) => ({
                            value: opt.value,
                            label: opt.label,
                        }))}
                    />
                </div>
            </div>
            <div className="result">
                <h2>Converted Amount:</h2>
                {output && <p>{`${input} ${from.toUpperCase()} = ${output} ${to.toUpperCase()}`}</p>}
            </div>
        </div>
    );
};

export default CurrencyConverter;
