import React from "react";
import {CardType} from "../../../../Utils/Enums/CardType";
import {Button, Form, Input, Modal, notification, Select} from "antd";

const { Option } = Select;

const CreateCardForm = ({ userId, onClose, onSubmit }) => {
    const [form] = Form.useForm();

    const formatCardNumber = (value) => {
        return value.replace(/(\d{4})(?=\d)/g, "$1 ").trim();
    };

    const handleCardNumberChange = (e) => {
        let value = e.target.value.replace(/\D/g, "");
        if (value.length > 16) value = value.slice(0, 16);
        form.setFieldsValue({ cardNumber: formatCardNumber(value) });
    };

    const handleExpirationChange = (e) => {
        let value = e.target.value.replace(/\D/g, "");
        if (value.length > 4) value = value.slice(0, 4);
        if (value.length >= 3) {
            value = `${value.slice(0, 2)}/${value.slice(2)}`;
        }
        form.setFieldsValue({ expiration: value });
    };

    const handleSubmit = (values) => {
        const userCardDto = { ...values, userId };
        onSubmit(userId, userCardDto);
        onClose();
    };

    const handleSubmitFailed = () => {
        notification.error({
            message: "Form Validation Error",
            description: "Please correct the errors in the form and try again.",
        });
    };

    return (
        <Modal
            title="Create Card"
            open
            onCancel={onClose}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                onFinishFailed={handleSubmitFailed}
                initialValues={{
                    currency: "USD",
                    cardType: "Gold",
                }}
            >
                <Form.Item
                    label="Card Number"
                    name="cardNumber"
                    rules={[
                        { required: true, message: "Card number is required." },
                        { pattern: /^\d{4} \d{4} \d{4} \d{4}$/, message: "Card number must be 16 digits." },
                    ]}
                >
                    <Input
                        placeholder="Enter card number"
                        onChange={handleCardNumberChange}
                        maxLength={19}
                    />
                </Form.Item>

                <Form.Item
                    label="Currency"
                    name="currency"
                    rules={[
                        { required: true, message: "Currency is required." },
                        { pattern: /^[A-Za-z]{3}$/, message: "Currency should be a 3-letter code (e.g., USD)." },
                    ]}
                >
                    <Input placeholder="Enter currency (e.g., USD)" />
                </Form.Item>

                <Form.Item
                    label="Expiration (MM/YY)"
                    name="expiration"
                    rules={[
                        { required: true, message: "Expiration date is required." },
                        { pattern: /^(0[1-9]|1[0-2])\/\d{2}$/, message: "Enter a valid expiration date (MM/YY)." },
                    ]}
                >
                    <Input placeholder="MM/YY" onChange={handleExpirationChange} />
                </Form.Item>

                <Form.Item
                    label="CVV"
                    name="cvv"
                    rules={[
                        { required: true, message: "CVV is required." },
                        { pattern: /^\d{3}$/, message: "CVV must be exactly 3 digits." },
                    ]}
                >
                    <Input placeholder="Enter CVV" maxLength={3} />
                </Form.Item>

                <Form.Item
                    label="Cardholder Name"
                    name="cardholderName"
                    rules={[
                        { required: true, message: "Cardholder name is required." },
                        { pattern: /.*\S.*/, message: "Name cannot be empty or consist only of spaces." },
                    ]}
                >
                    <Input placeholder="Enter cardholder name" />
                </Form.Item>

                <Form.Item
                    label="Card Type"
                    name="cardType"
                    rules={[{ required: true, message: "Card type is required." }]}
                >
                    <Select>
                        <Option value={CardType.GOLD}>{CardType.GOLD}</Option>
                        <Option value={CardType.PLATINUM}>{CardType.PLATINUM}</Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit">
                            Add Card
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateCardForm;
