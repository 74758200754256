// Header.js
import { Link } from "react-router-dom";
import { useAuth } from "../AuthService/AuthProvider";
import './Header.css';
import { useNavigate } from "react-router-dom";
import { useState} from "react";
import Notifications from "./Notifications";
import {Spin} from "antd";
import logo from '../../assets/logo.png';

const Header = ({ children }) => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const handleLogout = () => {
        logout();
        navigate("/login");
    };

    const handleHomePage = () => {
        navigate('/home');
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    if (!user) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <>
            <nav className="navbar">
                <Notifications />
                <div className="logo">
		<img onClick={handleHomePage} src={logo}/>
		</div>
                <ul className={isOpen ? "nav-links open" : "nav-links"}>
                    {user.role === "[ROLE_ADMIN]" && <li className="lili"><Link to="/dashboard">Users</Link></li>}
                    {user.role !== "[ROLE_ADMIN]" && (<li className="lili"><Link to={`/user/${user.id}`}>Home</Link></li>)}
                    <li className="lili"><Link to="/converter">Converter</Link></li>
                    <li className="lili"><Link to="/" onClick={handleLogout}>Logout</Link></li>
                </ul>
                <div className="hamburger" onClick={toggleMenu}>
                <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
            </nav>
            <div className="child-content">
                {children}
            </div>
        </>
    );
};

export default Header;
