import React, { useState } from "react";
import { Modal, Input, Button, Select, notification } from "antd";
import "./SendNotificationModal.css";
import PropTypes from "prop-types";

const { TextArea } = Input;
const { Option } = Select;

const SendNotificationModal = ({
                                   users = [],
                                   onClose,
                                   onSubmit,
                               }) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");

    const filteredUsers = users.filter((user) => user.username.toLowerCase() !== "admin");

    const handleSubmit = () => {
        if (!subject || !body || selectedUsers.length === 0) {
            notification.error({
                message: "Validation Error",
                description: "Please fill in all fields and select at least one user.",
            });
            return;
        }
        onSubmit({ subject, body, userIds: selectedUsers });
    };

    return (
        <Modal
            open
            title="Send Notification"
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Send
                </Button>,
            ]}
        >
            <div className="form-group">
                <label htmlFor="notification-title">Title</label>
                <Input
                    id="notification-title"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Enter notification title"
                />
            </div>

            <div className="form-group">
                <label htmlFor="notification-body">Message</label>
                <TextArea
                    id="notification-body"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    placeholder="Enter message body"
                    rows={4}
                />
            </div>

            <div className="form-group">
                <label>Select Users</label>
                <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select users"
                    value={selectedUsers}
                    onChange={setSelectedUsers}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {filteredUsers.map((user) => (
                        <Option key={user.id} value={user.id}>
                            {user.username}
                        </Option>
                    ))}
                </Select>
            </div>
        </Modal>
    );
};

SendNotificationModal.propTypes = {
    users: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            username: PropTypes.string.isRequired,
        })
    ).isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default SendNotificationModal;
