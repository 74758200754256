// Header.js
import { Link } from "react-router-dom";
import './Footer.css';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import logo from '../../assets/logo.png';

const Footer = ({ children }) => {
    
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const handleLicenseClick = () =>{
        navigate('/license')
    }

    const handleTermsClick = () =>{
        navigate('/terms')
    }

    const handlePrivacyClick = () =>{
        navigate('/privacy')
    }

    const handleHomePage = () => {
        navigate('/home');
    };

 
    return (
        <>
            <nav className="navbarr">
                <div className="logo">
                    <img onClick={handleHomePage} src={logo}/>
                </div>
                <ul className="elpadre">
                <li className="ochko"><a className="linka" href="https://t.me/truepathbank">Contact Us</a></li>
            <li className="ochko" onClick={handleLicenseClick}>License</li>
            <li className="ochko" onClick={handleTermsClick}>Terms of use</li>
            <li className="ochko" onClick={handlePrivacyClick}>Privacy Policy</li>
          </ul>
               
            </nav>
            
        </>
    );
};

export default Footer;
