import transactionApiClient from "./TransactionApiClient";

const API_URL = `${process.env.REACT_APP_API_URL}/notifications`;

export const NotificationService = {
    /**
     * Sends a notification to multiple users.
     * @param {string} subject - The subject of the notification.
     * @param {string} message - The message content of the notification.
     * @param {number[]} userIds - Array of user IDs to send the notification to.
     * @returns {Promise<Object>} The response data.
     */
    async sendNotification(subject, message, userIds) {
        const response = await transactionApiClient.post(API_URL, {
            subject,
            message,
            userIds,
        });
        return response.data;
    },

    /**
     * Retrieves notifications for a specific user.
     * @param {number} userId - The ID of the user.
     * @param {boolean} [isRead=false] - Filter by read/unread status.
     * @returns {Promise<Array>} List of notifications.
     */
    async getNotifications(userId, isRead = false) {
        const response = await transactionApiClient.get(API_URL, {
            params: {
                userId,
                isRead,
            },
        });
        return response.data;
    },

    /**
     * Marks a specific notification as read for a user.
     * @param {number} notificationId - The ID of the notification.
     * @param {number} userId - The ID of the user.
     * @returns {Promise<void>} Resolves when the request is complete.
     */
    async markNotificationAsRead(notificationId, userId) {
        const response = await transactionApiClient.put(
            `${API_URL}/${notificationId}/markRead`,
            null, // No request body, query params are used instead
            {
                params: {
                    userId,
                },
            }
        );
        return response.data;
    },

    /**
     * Marks all notifications as read for a user.
     * @param {number} userId - The ID of the user.
     * @returns {Promise<void>} Resolves when the request is complete.
     */
    async markAllNotificationsAsRead(userId) {
        const response = await transactionApiClient.put(`${API_URL}/markAllRead`, null, {
            params: {
                userId,
            },
        });
        return response.data;
    },
};
