import React, {useState} from 'react';
import {Button, Modal, Form, Input, notification} from 'antd';
import {useAuth} from '../../AuthService/AuthProvider';

const MoneyTransferComponent = ({userHasCard, handleMoneyTransferSubmit, balance}) => {
    const {user} = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleFormSubmit = (values) => {
        const {amountToSend} = values;

        if (user?.role !== '[ROLE_ADMIN]' && parseFloat(amountToSend) > balance) {
            notification.error({
                message: 'Transaction Failed',
                description: 'Transaction amount exceeds available balance.',
            });
            return;
        }

        handleMoneyTransferSubmit(values);
        handleModalCancel();
    };

    return (
        <>
            <Button
                size="large"
                type="primary"
                onClick={showModal}
                title={!userHasCard ? 'Please create a card before making a money transfer' : ''}
            >
                Create Transaction
            </Button>

            <Modal
                title={user?.role === '[ROLE_ADMIN]' ? 'Create Deposit Transaction' : 'Create Withdrawal Transaction'}
                open={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFormSubmit}
                >
                    <Form.Item
                        label="Destination Bank"
                        name="destinationBank"
                        rules={[
                            {required: true, message: 'Please enter the destination bank!'},
                            {pattern: /^\S+$/, message: 'No spaces are allowed.'},
                        ]}
                    >
                        <Input placeholder="Enter bank name"/>
                    </Form.Item>

                    <Form.Item
                        label="Destination Country"
                        name="destinationCountry"
                        rules={[
                            {required: true, message: 'Please enter the destination country!'},
                            {pattern: /^\S+$/, message: 'No spaces are allowed.'},
                        ]}
                    >
                        <Input placeholder="Enter country"/>
                    </Form.Item>

                    <Form.Item
                        label="Account Number"
                        name="accountNumber"
                        rules={[
                            {required: true, message: 'Please enter the account number!'},
                            {pattern: /^\S+$/, message: 'No spaces are allowed.'},
                        ]}
                    >
                        <Input placeholder="Enter account number"/>
                    </Form.Item>

                    <Form.Item
                        label={user?.role === '[ROLE_ADMIN]' ? 'Amount to Deposit' : 'Amount to Withdraw'}
                        name="amountToSend"
                        rules={[
                            {
                                required: true,
                                validator: (_, value) => {
                                    if (!value || isNaN(value) || Number(value) < 1) {
                                        return Promise.reject(new Error('Amount must be at least 1.'));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter amount"
                            type="number"
                        />
                    </Form.Item>

                    {user?.role !== '[ROLE_ADMIN]' && (
                        <>
                            <Form.Item
                                label="BIC (Optional)"
                                name="bic"
                                normalize={(value) => (value === '' ? undefined : value)}
                                rules={[
                                    { pattern: /.{8,11}/, message: 'BIC must be 8 to 11 characters.' },
                                ]}
                            >
                                <Input placeholder="BIC Code (8-11 characters)" />
                            </Form.Item>

                            <Form.Item
                                label="SWIFT (Optional)"
                                name="swift"
                                normalize={(value) => (value === '' ? undefined : value)}
                                rules={[
                                    { pattern: /.{8,11}/, message: 'SWIFT must be 8 to 11 characters.' },
                                ]}
                            >
                                <Input placeholder="SWIFT Code (8-11 characters)" />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            {user?.role === '[ROLE_ADMIN]' ? 'Deposit Now' : 'Withdraw Now'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default MoneyTransferComponent;
