import React, { useEffect, useRef, useState } from "react";
import { Badge, Dropdown, notification, Spin } from "antd";
import { BellOutlined } from "@ant-design/icons";
import "./Notifications.css";
import { NotificationService } from "../../services/NotificationService";
import { useAuth } from "../AuthService/AuthProvider";

const Notifications = () => {
    const { user } = useAuth();
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [readNotifications, setReadNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        fetchNotifications();
    }, [user.id]);

    const fetchNotifications = async () => {
        setLoading(true);
        try {
            const [unreadResponse, readResponse] = await Promise.all([
                NotificationService.getNotifications(user.id, false),
                NotificationService.getNotifications(user.id, true),
            ]);
            setUnreadNotifications(unreadResponse || []);
            setReadNotifications(readResponse || []);
        } catch (error) {
            notification.error({
                message: "Error",
                description: `Failed to fetch notifications: ${error.response?.data || "Unknown error"}`,
            });
        } finally {
            setLoading(false);
        }
    };

    const markAsRead = async (notificationId) => {
        try {
            await NotificationService.markNotificationAsRead(notificationId, user.id);
            setUnreadNotifications((prev) => prev.filter((notif) => notif.id !== notificationId));
            setReadNotifications((prev) => [
                ...prev,
                unreadNotifications.find((notif) => notif.id === notificationId),
            ]);
        } catch (error) {
            notification.error({
                message: "Error",
                description: `Failed to mark notification as read: ${error.response?.data || "Unknown error"}`,
            });
        }
    };

    const markAllAsRead = async () => {
        try {
            await NotificationService.markAllNotificationsAsRead(user.id);
            setReadNotifications((prev) => [...prev, ...unreadNotifications]);
            setUnreadNotifications([]);
            setDropdownOpen(false);
        } catch (error) {
            notification.error({
                message: "Error",
                description: `Failed to mark all notifications as read: ${error.response?.data || "Unknown error"}`,
            });
        }
    };

    const dropdownContent = (
        <div className="notification-dropdown">
            <div className="ant-dropdown-menu" style={{ maxHeight: "600px", overflowY: "auto" }}>
                {unreadNotifications.map((notif) => (
                    <div
                        key={notif.id}
                        className="notification-item unread"
                        onClick={() => markAsRead(notif.id)}
                    >
                        <strong className="ant-list-item-meta-title">{notif.subject}</strong>
                        <p className="ant-list-item-meta-description">{notif.message}</p>
                    </div>
                ))}
                {readNotifications.map((notif) => (
                    <div key={notif.id} className="notification-item">
                        <strong className="ant-list-item-meta-title">{notif.subject}</strong>
                        <p className="ant-list-item-meta-description">{notif.message}</p>
                    </div>
                ))}
            </div>
            {unreadNotifications.length > 0 ? (
                <div className="mark-all-container sticky-mark-all">
                    <button className="mark-all-btn" onClick={markAllAsRead}>
                        Mark all as read
                    </button>
                </div>
            ) : (
                <p className="no-notifications">No notifications</p>
            )}
        </div>
    );

    const dropdownRef = useRef(null);

    return (
        <Dropdown
            className="notification-dropdown"
            dropdownRender={() => dropdownContent}
            placement="bottomRight"
            trigger={["click"]}
            open={dropdownOpen}
            onOpenChange={setDropdownOpen}
            getPopupContainer={() => dropdownRef.current || document.body}
        >
            <div ref={dropdownRef}>
                <Spin spinning={loading}>
                    <Badge
                        count={unreadNotifications.length}
                        className="notifications-badge"
                    >
                        <BellOutlined />
                    </Badge>
                </Spin>
            </div>
        </Dropdown>
    );
};

export default Notifications;
