import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {FaCheck, FaTimes, FaEdit, FaTrashAlt, FaCreditCard} from 'react-icons/fa';
import './UserList.css';
import UserFormModal from "../UserForms/CreateForm/UserFormModal/UserFormModal";
import CreateCardForm from "../UserForms/CreateForm/CreateCardForm/CreateCardForm";
import {UserCardService} from "../../../services/UserCardService";
import {UserService} from "../../../services/UserService";
import SendNotificationModal from "../SendNotificationModal/SendNotificationModal";
import {Button, Input, notification, Space, Table, Tooltip} from 'antd';
import {NotificationService} from "../../../services/NotificationService";


const UserList = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showCreateCardDialog, setShowCreateCardDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showNotificationModal, setShowNotificationModal] = useState(false);

    const handleCloseModalDialog = () => {
        setShowModal(false);
        setSelectedUser(null);
    };

    const handleCloseCreateCardDialog = () => {
        setShowCreateCardDialog(false);
        setSelectedUser(null);
    };

    useEffect(() => {
        UserService.getAllUsers().then(response => {
            setUsers(response);
        }).catch(error => {
            notification.error({
                message: 'Error',
                description: `Failed to load users: ${error.response?.data || 'Unknown error'}`,
            });
        });
    }, []);

    const handleRowClick = (userId) => {
        const user = users.find((user) => user.id === userId);
        if (!user?.hasCard) {
            return;
        }
        if (user?.hasCard) {
            navigate(`/user/${userId}`, {state: {hasCard: user?.hasCard ?? false}});
        }
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const handleAddUser = () => {
        setShowModal(true);
    };

    const handleCreateUserCard = (user) => {
        setSelectedUser(user);
        setShowCreateCardDialog(true);
    };

    const handleDelete = (userId) => {
        UserService.deleteUser(userId).then(() => {
            setUsers((prevUsers) => prevUsers.filter(user => user.id !== userId));

            notification.success({
                message: 'Success',
                description: 'User deleted successfully!',
            });
        }).catch((error) => {

            notification.error({
                message: 'Error',
                description: `Failed to delete user: ${error.response?.data || 'Unknown error'}`,
            });
        });
    };

    const handleCreateCardForUser = (userId, userCardDto) => {
        const cardData = {
            ...userCardDto,
            cardNumber: userCardDto.cardNumber.replace(/\s/g, '')
        };
        setSelectedUser(null);
        UserCardService.addCardForUser(selectedUser.id, cardData).then(() => {
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.id === selectedUser.id ? { ...user, hasCard: true } : user
                )
            );
            setShowCreateCardDialog(false);

            notification.success({
                message: 'Success',
                description: 'Card created successfully!',
            });
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: `Failed to create card: ${error.response?.data || 'Unknown error'}`,
            });
        });
    };

    const handleModalSubmit = (user) => {
        if (user.id) {
            updateUser(user);
        } else {
            addUser(user);
        }
        setShowModal(false);
        setSelectedUser(null);
    };

    const updateUser = (user) => {
        UserService.updateUser(user.id, user.username, user.password)
            .then((updatedUser) => {
                setUsers((prevUsers) =>
                    prevUsers.map((u) => (u.id === updatedUser.id ? updatedUser : u))
                );
                notification.success({
                    message: 'User Updated',
                    description: `User "${user.username}" was successfully updated.`,
                });
            })
            .catch((error) => {
                notification.error({
                    message: 'Error',
                    description: `Failed to update user: ${error.response?.data || 'Unknown error'}`,
                });
            });
    };

    const addUser = (user) => {
        UserService.addUser(user.username)
            .then((newUser) => {
                setUsers((prevUsers) => [...prevUsers, newUser]);
                notification.success({
                    message: 'User Added',
                    description: `User "${user.username}" was successfully created.`,
                });
            })
            .catch((error) => {
                notification.error({
                    message: 'Error',
                    description: `Failed to create user: ${error.response?.data || 'Unknown error'}`,
                });
            });
    };

    const handleSendNotification = ({ subject, body, userIds }) => {
        NotificationService.sendNotification(subject, body, userIds)
            .then(() => {
                notification.success({
                    message: 'Notification Sent',
                    description: 'Notification sent successfully!',
                });
                setShowNotificationModal(false);
            })
            .catch((error) => {
                notification.error({
                    message: 'Error',
                    description: `Failed to send notification: ${error.response?.data || 'Unknown error'}`,
                });
            });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredUsers = users.filter(user =>
        user.username?.toLowerCase().includes(searchTerm)
    );

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            align: 'center',
            render: (_, user) => user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : '',
        },
        {
            title: 'Email',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
        },
        {
            title: 'Registered',
            dataIndex: 'registered',
            key: 'registered',
            render: (registered) => (
                registered ? <FaCheck color="green" /> : <FaTimes color="red" />
            ),
            align: 'center',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, item) => (
                <Space>
                    <Tooltip title="Edit">
                        <Button
                            icon={<FaEdit />}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEdit(item);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Button
                            icon={<FaTrashAlt />}
                            danger
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(item.id);
                            }}
                        />
                    </Tooltip>
                    {!item.hasCard && (
                        <Tooltip title="Create Card">
                            <Button
                                icon={<FaCreditCard />}
                                type="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCreateUserCard(item);
                                }}
                            />
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <div className="table-container">
            <div className="header-container">
                <h1>Users List</h1>
                <div className="header-actions">
                    <Input
                        type="search"
                        size="large"
                        placeholder="Search by name"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                    <Button size="large" onClick={handleAddUser} type="primary">Add User</Button>
                    <Button size="large" onClick={() => setShowNotificationModal(true)} type="primary">Send Notification</Button>
                </div>
            </div>

            <Table
                dataSource={filteredUsers}
                columns={columns}
                rowKey="id"
                onRow={(record) => ({
                    onClick: () => handleRowClick(record.id),
                    className: !record.hasCard ? 'disabled-row' : 'enabled-row',
                    title: !record.hasCard ? 'Please create a card to view this user' : '',
                })}
                pagination={{ pageSize: 10 }}
                className="user-table"
                scroll={{ x: 'max-content' }}
            />

            {showModal && (
                <UserFormModal
                    user={selectedUser}
                    onClose={handleCloseModalDialog}
                    onSubmit={handleModalSubmit}
                />
            )}
            {showCreateCardDialog && (
                <CreateCardForm
                    onClose={handleCloseCreateCardDialog}
                    onSubmit={handleCreateCardForUser}
                />
            )}
            {showNotificationModal && (
                <SendNotificationModal
                    users={users}
                    onClose={() => setShowNotificationModal(false)}
                    onSubmit={handleSendNotification}
                />
            )}
        </div>
    );
};

export default UserList;